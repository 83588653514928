import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './fiidr/App'
import './index.css'
import './animations.css'
import './themes.css'
import './fonts.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
// Default dark theme
document.body.classList.add('theme-dark')
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)