import { Link } from 'react-router-dom'
import './Button.scoped.css'

function Button(props) {
    const classes = 'button ' + (props.className ? props.className : '') + (props.steam ? ' steam ' : '')

    if (props.href) {
        return (
                props.download ? 
                    <a className={ classes } href={props.href} target={props.newtab ? 'blank' : ''}> 
                        {props.children}
                    </a>
                :
                    <Link className={ classes } to={props.href} target={props.newtab ? 'blank' : ''}> 
                        {props.children}
                    </Link>
        )
    } else {
        return (
            <div className={ classes }>
                {props.children}
            </div>
        )
    }
}

export default Button