import { useState } from "react"
import Loading from "../loading/Loading"
import "./Frame.css"

function Frame(props) {
    const src = props.src
    const [isLoading, setLoading] = useState(true)

    function onLoad() {
        setLoading(false)
    }

    return (
        <div className="frame-container">
            <Loading />
            <iframe style={{ display: isLoading ? "none" : "block" }} onLoad={onLoad} className="fade-in" src={src ?? "/"} allow="fullscreen"/>
        </div>
    )
}

export default Frame