function getBetween(first, second, from) {
    return from.substring(
        from.lastIndexOf(first) + first.length, 
        from.lastIndexOf(second))
}

function getBetweenFirst(first, second, from) {
    const temp = first == null || !from.includes(first) ? from : from.substring(from.indexOf(first) + first.length)
    return second == null || !temp.includes(second) ? temp : temp.substring(0, temp.indexOf(second))
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

module.exports = {
    getBetween,
    getBetweenFirst,
    capitalizeFirstLetter
}