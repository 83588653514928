import { API_HOST, getRequestOptions } from "./api"

async function getApps(creds) {
    const res = await fetch(
        API_HOST + '/apps', 
        getRequestOptions('GET', creds)
    )
    return await res.json()
}

async function searchApps(query, creds) {
    const res = await fetch(
        API_HOST + `/apps?query=${query}`,
        getRequestOptions('GET', creds)
    )
    return await res.json()
}

async function getApp(id, creds) {
    const res = await fetch(
        API_HOST + `/apps/${id}`, 
        getRequestOptions('GET', creds)
    )
    return await res.json()
}

export {
    getApp,
    searchApps,
    getApps
}