import { getUserIconPath } from '../../api/users/users';
import './NavUser.scoped.css';

function NavUser(props) {
    const userData = props.userData

    return (
        <a className={'nav-user'} onClick={props.onClick}>
            <img className="usericon" src={getUserIconPath(userData.id)} />
        </a>
    )
}

export default NavUser;
