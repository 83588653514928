import { useRef } from 'react';
import './Search.scoped.css';

function Search(props) {
    const searchRef = useRef()
    const value = props.value

    const onFormSubmit = (e) => {
        e.preventDefault()
        handleSearch()
    }

    const handleSearch = async () => {
        let search = searchRef.current?.value
        if (search) {
            search = search.trim()
        }
        props.callback(search)
    }

    return (
        <form className="search" onSubmit={onFormSubmit} autoComplete="off">
            <input defaultValue={value} name='search' autoComplete="off" type='text' placeholder='Search...' ref={searchRef}></input>
            <button type="submit" className="button" onClick={handleSearch}>Search</button>
        </form>
    );
}


export default Search;
