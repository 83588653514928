import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { checkIfIsAlive } from '../../../../api/api'
import Loading from '../../../../components/loading/Loading'
import './ServiceLink.scoped.css'
import { UserContext } from '../../../../auth/userSession'

function ServiceLink(props) {
    const href = props.href
    const title = props.title
    const allow = props.allow
    const subtitle = props.subtitle
    const ctx = useContext(UserContext)
    const classes = 'service-link ' + (props.className ? props.className : '')

    if (allow && !ctx.roles?.includes(allow) && !ctx.roles?.includes('admin')) {
        return null
    }

    return (
        <Link className={ classes } to={href}>
            <div className={`service-title ${subtitle ? 'small-title' : ''}`}>{title}</div>
            <div className="service-subtitle">{subtitle}</div>
        </Link>
    )
}

export default ServiceLink