import { API_HOST, getRequestOptions } from "./api"

async function getGames(creds) {
    const res = await fetch(
        API_HOST + '/games', 
        getRequestOptions('GET', creds)
    )
    return await res.json()
}

async function searchGames(query, creds) {
    const res = await fetch(
        API_HOST + `/games?query=${query}`, 
        getRequestOptions('GET', creds)
    )
    return await res.json()
}

async function getGame(store, id, creds) {
    const res = await fetch(
        API_HOST + `/games/${store}/${id}`, 
        getRequestOptions('GET', creds)
    )
    return await res.json()
}

export {
    getGame,
    getGames,
    searchGames
}