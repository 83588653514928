const cpack = require('./unpacker')
const stringUtils = require('./stringUtils')

const getters = {
    vidoza: getVidoza,
    mixdrop: getMixdrop,
    mail: getCloudMailRu
}

async function getVideo(url) {
    const videoUrl = await fetchRedirect(url)
        .then(res => res.text())
        .then(html => {
            const link = getMp4(url, html)
            return link
        })
    
    return videoUrl
}

async function fetchRedirect(url) {
    url = url.substring(url.indexOf('://') + 3)
    const res = await fetch(`/proxy/${url}`)
    const resultUrl = res.url.substring(res.url.indexOf('/proxy/') + 7)
    if (resultUrl != url) {
        return fetchRedirect(resultUrl)
    }
    return res
}

function getMp4(url, text) {
    for (let host in getters) {
        if (url.includes(host))
            return getters[host](url, text)
    }
}

function getVidoza(url, text) {
    return stringUtils.getBetween('<source src=\"', '\" type=\'video/mp4\'', text)
}

function getCloudMailRu(url, text) {
    const id = url.match(/(?:\/\/|\.)cloud\.mail\.ru\/public\/(.+)/)[1]
    return 'https://cloclo1.cloud.mail.ru/weblink/view/' + id
}

function getMixdrop(url, text) {
    let packed = stringUtils.getBetweenFirst('eval(function(', '\n</script>\n<video', text)
    packed = `eval(function(${packed}`
    const unpacked = cpack.unPack(packed)
    let video = stringUtils.getBetween('wurl="', '";\nMDCore.vfile', unpacked)
    if (video.startsWith('//'))
        video = `https:${video}`
    return video
}

export default getVideo