import { useState } from "react"
import { Link } from "react-router-dom"
import "./ProductPreview.scoped.css"

function ProductPreview(props) {
    const [isLoading, setLoading] = useState(true)
    const product = props.product
    const href = props.href
    
    function onLoad() {
        setLoading(false)
    }
    

    return (
        <Link className="product-preview fade-in" to={href}>
            <img className="product-preview-image fade-in" src={product.poster} onLoad={onLoad} style={{ display: isLoading ? "none" : "block" }}/>
            <img className="product-preview-image" src='/poorstore/res/product-placeholder.png' style={{ display: isLoading ? "block" : "none" }}/>
            {product.note ? <span className="note">{product.note}</span> : null}
            {product.title ? <span className="title">{product.title}</span> : null}
        </Link>
    )
}

export default ProductPreview;