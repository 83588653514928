import { createContext } from 'react'

const SESSION_STORAGE_KEY = 'UserCredentials'

/**
 * User
 * - scheme
 * - content
 * - loginDate
 * 
 * - username
 * - id
 * - displayName
 */

function generateCredentials(username, password, id) {
    return {
        scheme: 'Basic',
        content: `${username}:${password}`,
        loginDate: new Date(),
        username: username,
        id: id,
    }
}

function getStoredCredentials() {
    let toReturn = null
    const storedCredentials = localStorage.getItem(SESSION_STORAGE_KEY)
    if (storedCredentials) {
        try {
            toReturn = JSON.parse(storedCredentials)
        } catch {
            logOut()
            toReturn = null
        }
    }

    return toReturn
}

function logIn(username, password, id) {
    const credentials = generateCredentials(username, password, id)
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(credentials))
    return credentials
}

function logOut() {
    localStorage.removeItem(SESSION_STORAGE_KEY)
}

function getDataFromCreds(creds) {
    if (!creds) return [null, null]
    return creds.content.split(':')
}

const UserContext = createContext(undefined)

function getInitialContext(state, setState) {
    return {
        credentials: state,
        logIn: (username, password, id) => {
            const credentials = logIn(username, password, id)
            setState(credentials)
        },
        logOut: () => {
            logOut()
            setState(null)
        },
        roles: state?.roles,
        displayName: state?.displayName
    }
}

export {
    generateCredentials,
    getInitialContext,
    getStoredCredentials,
    logOut,
    UserContext
}