import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function RequiresAuth({ userCreds, loginPageRoute = '/login', children }) {
    const userValid = userCreds != null

    if (!userValid) {
        return <Navigate to={ loginPageRoute } />
    }

    return (
            children ? children : <Outlet />
    )
}

export {
    RequiresAuth
}