import { Link, useLocation } from 'react-router-dom';
import './NavItem.scoped.css';
import { UserContext } from '../../auth/userSession';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavItem(props) {
    const location = useLocation()
    const href = props.href
    const isLogo = props.logo
    const icon = props.icon
    const external = props.external
    const allow = props.allow

    const ctx = useContext(UserContext)

    if (allow && !ctx.roles?.includes(allow) && !ctx.roles?.includes('admin')) {
        return null 
    }

    const externalContext = location.pathname.includes('external')

    const classes = (
        ((href === '/' && location.pathname === '/')
    ||
        (href !== '/' && location.pathname.includes(href))
        ? ' enabled' : '')
    +
        (externalContext && !isLogo ? ' external-ctx' : '')
    + 
        (props.className ? ' ' + props.className : '')
    )

    if (external) {
        return (
            <a className={'nav-item' + classes} href={props.href} target="_blank">
                {props.children}
            </a>
        )
    }

    return (
        isLogo
        ?
        <Link className={'logo' + classes} to={props.href}>
            <img src="/favicon.png" />
            <span className="logo-title">FIIDR</span>
        </Link>
        :
        <Link className={'nav-item' + classes} to={props.href}>
            {icon ? <div className="icon"><FontAwesomeIcon icon={icon} /></div> : null}
            {props.children}
        </Link>
    )
}

export default NavItem;
