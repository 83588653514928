import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Button from "../../../../components/button/Button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import "./AppDetails.css"
import { UserContext } from "../../../../auth/userSession"
import { getApp } from "../../../../api/apps"
import Loading from "../../../../components/loading/Loading"

function AppDetails(props) {
    const ctx = useContext(UserContext)
    let { id } = useParams();
    const [app, setApp] = useState('');

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            const data = await getApp(id, ctx.credentials)
            setApp(data)
        };

        dataFetch();
    }, [ctx.credentials, id]);
    
    const gameStyle = { "--game-background": `url(${app.background})` }

    if (app !== '') {
        return (
            <div style={gameStyle} className="game-details fade-in">
                <div style={gameStyle} className="game-header">
                    <div className="game-info">
                        <h1 className="game-details-metadata-title">{app.title}</h1>
                        <Button download className="game-details-download" href={app.downloadUri}><FontAwesomeIcon className="game-details-download-icon" icon={faDownload} />Download</Button>
                    </div>
                </div>
                <div className="game-meta">
                    <img className="game-details-image game-image-desktop" src={app.poster} />
                    <img className="game-details-image game-image-mobile" src={app.header} />
                    <p className="game-details-metadata-description">{app.description}</p>
                </div>
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }

}

export default AppDetails
