import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import ProductPreview from "../product-preview/ProductPreview";
import "./ProductList.scoped.css"
import Notice from "../notice/Notice";

function ProductList(props) {
    const title = props.title
    const query = props.query
    const getProducts = props.onGet
    const searchProducts = props.onSearch
    const productBaseHref = props.baseHref
    const productType = props.productType

    const [products, setProducts] = useState(null);

    useEffect(() => {
        let isCancelled = false
        setProducts(null)

        const dataFetch = async () => {
            let data;
            if (!query || query === '') {
                data = await getProducts()
            } else {
                data = await searchProducts(query)
            }

            if (isCancelled) return
            setProducts(data)
        }
    
        dataFetch()

        return () => {
            isCancelled = true
        }
    }, [productBaseHref, productType, query]);

    if (products != null && products.errorMessage != null) {
        return (
            <div className="products-list">
                {title ? <span className="products-title">{title}</span> : null}
                <div className="products" style={{'display': 'block'}}>
                    <Notice warn>{products.errorMessage}</Notice>
                </div>
            </div>
        )
    }

    if (products != null) {
        return (
            <div className="products-list">
                {title ? <span className="products-title">{title}</span> : null}
                <div className="products">
                    {products.map(product => 
                        <ProductPreview key={product.id} product={product} href={`/${productBaseHref}/${product.id}`}/>
                    )}
                </div>
            </div>
        )
    }
    
    if (products != null && products.length === 0) {
        return (
            <h1>No Results Found</h1>
        )
    }

    return (
        <div className="products-list">
            {title ? <span className="products-title">{title}</span> : null}
            <div className="products">
                <Loading simple />
            </div>
        </div>
    )
}

export default ProductList;