import { useState } from "react"
import "./Notice.scoped.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleXmark, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

function Notice({className, children, warn}) {
    const [closed, setClosed] = useState(false)
    if (!className) className = ''

    if (warn) {
        className = className + ' error'
    }

    const onClose = () => {
        setClosed(true)
    }

    return (
        <div className={`${className} ${closed ? 'closed' : 'fade-in'}`}>
            <span className="close-button" onClick={onClose}>
                <FontAwesomeIcon icon={faRectangleXmark} />
            </span>
            <span className="info-icon">
                {warn ? <FontAwesomeIcon icon={faTriangleExclamation} /> : <FontAwesomeIcon icon={faCircleInfo} /> }

            </span>
            <span>
                {children}
            </span>
        </div>
    )
}

export default Notice