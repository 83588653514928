import { Buffer } from 'buffer';
const API_HOST = String(process.env.REACT_APP_FIIDR_API_HOST).trim()

function getRequestOptions(method, credentials, body = null) {
    return {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `${credentials?.scheme} ${Buffer.from(`${credentials?.content}`).toString('base64')}`
        },
        body: body ? JSON.stringify(body) : null
    }
}

function checkIfIsAlive(url) {
    let times = 3
    const delay = 1000

    return new Promise((res, rej) => {                       // return a promise

        (function rec(i) {                                   // recursive IIFE
            fetch(url, {mode: 'no-cors'}).then((r) => {      // fetch the resourse
                res(r);                                      // resolve promise if success
            }).catch( err => {
                if (times === 0)                             // if number of tries reached
                    return rej(err);                         // don't try again

                setTimeout(() => rec(--times), delay )       // otherwise, wait and try 
            });                                              // again until no more tries
        })(times);

    });
}

export {
    API_HOST,
    getRequestOptions,
    checkIfIsAlive
}