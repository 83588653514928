import ServiceLink from './components/service-link/ServiceLink';
import ServiceGroup from './components/service-group/ServiceGroup';
import "./Home.css"
import Notice from '../../components/notice/Notice';

function Home(props) {
  const user = props.user
  const userRoles = user.roles ?? []

  return (
    <div className="home fade-in">
        <Notice>
            Welcome to FIIDR!
            <hr />
            <ServiceGroup allowAll title="Get the Jellyfin App!">
                <ServiceLink small title="Windows" href="https://github.com/jellyfin/jellyfin-media-player/releases/download/v1.11.1/JellyfinMediaPlayer-1.11.1-windows-x64.exe" className="small-store home-store fade-in" />
                <ServiceLink small title="Android" href="https://play.google.com/store/apps/details?id=org.jellyfin.mobile" className="small-store home-store fade-in" />
                <ServiceLink small title="iOS" href="https://apps.apple.com/ca/app/swiftfin/id1604098728" className="small-store home-store fade-in" />
            </ServiceGroup>
            <hr />
            • Jellyfin server address: jellyfin.fiidr.xyz
        </Notice>
        <ServiceGroup allowAll title="Games and Apps">
            <ServiceLink allow="games" title="Games" href="/games" className="home-store fade-in" />
            <ServiceLink allow="apps" title="Apps" href="/apps" className="home-store fade-in" />
            { /* <ServiceLink allow="media" title="Movies" href="/movies" className="home-store fade-in" /> */}
            { /* <ServiceLink allow="media" title="Shows" href="/shows" className="home-store fade-in" /> */ }
        </ServiceGroup>
        <ServiceGroup title="Movies and Shows" allow="jf">
            <ServiceLink allow="jf" title="Jellyfin Web" subtitle="Watch Movies and Shows" href="https://jellyfin.fiidr.xyz" className="home-jellyfin fade-in" />
            <ServiceLink allow="jf" title="Jellyseerr" subtitle="Request Movies and Shows" href="https://jellyseerr.fiidr.xyz" className="home-jellyseerr fade-in" />
            { /* <ServiceLink allow="mc" title="Minecraft Server" href="/external/mcmap" className="home-mc-map fade-in" /> */ }
        </ServiceGroup>
        <ServiceGroup title="Admin Services" userRoles={userRoles} allow="admin">
            <ServiceLink title="Sonarr" href="/external/sonarr" className="home-sonarr fade-in" />
            <ServiceLink title="Radarr" href="/external/radarr" className="home-radarr fade-in" />
            <ServiceLink title="Bazarr" href="/external/bazarr" className="home-bazarr fade-in" />
            <ServiceLink title="Prowlarr" href="/external/prowlarr" className="home-prowlarr fade-in" />
            <ServiceLink title="qBitTorrent" href="/external/qbit" className="home-qbit fade-in" />
        </ServiceGroup>
    </div>
  )
}

export default Home
