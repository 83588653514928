import { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { UserContext } from "../../auth/userSession"
import ProductList from "../../components/product-list/ProductList"
import Search from "../../components/search/Search"
import "./Apps.scoped.css"
import { getApps, searchApps } from "../../api/apps"
import Notice from "../../components/notice/Notice";

function Apps() {
    const ctx = useContext(UserContext)

    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.get('query') ?? ''
  
    const onNewQuery = (newQuery) => {
        if (newQuery !== query) {
            if (newQuery) {
                setSearchParams({ 
                    query: newQuery
                })
            } else {
                setSearchParams({ })
            }
        }
    }

    const onGet = async () => {
        const apps = await getApps(ctx.credentials)
        apps.map(game => {
            delete game.title
        })
        return apps
    }

    const onSearch = async () => {
        const apps = await searchApps(query, ctx.credentials)
        apps.map(game => {
            delete game.title
        })
        return apps
    }

    return (
        <div className="apps">
            <Notice>
                Downloads are powered by torrents. Make sure you have a torrent client installed, like <a target="_blank" href="https://www.fosshub.com/qBittorrent.html?dwl=qbittorrent_4.5.2_x64_setup.exe">qBitTorrent</a>!</Notice>
            <Notice>
                Apps are added manually by FIIDR's administration team. Please contact a site admin to request an app.
            </Notice>
            <div className="movies-search">
                <Search value={query} callback={onNewQuery}/>
            </div>
            <ProductList query={query} onGet={onGet} baseHref={'apps'} onSearch={onSearch}/>
        </div>
    )
}

export default Apps
