import { API_HOST, getRequestOptions } from "../api"

async function getAuthUser(credentials) {
    const res = await fetch(
        API_HOST + '/auth/user', 
        getRequestOptions('GET', credentials)
    )
    return await res.json()
}

async function loginUser(username, password) {
    const resp = await fetch("/api/auth/login", {
        method: 'POST', 
        headers: {
            //'Content-Type': 'application/json'
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        body: JSON.stringify({username: username, password: password})})
    return await resp.json()
}

function getUserIconPath(id) {
    return API_HOST + `/usericons/${id}.png`
}

export {
    loginUser,
    getAuthUser,
    getUserIconPath
}