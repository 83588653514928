import { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { UserContext } from "../../auth/userSession"
import { getGames, searchGames } from "../../api/games";
import ProductList from "../../components/product-list/ProductList"
import Search from "../../components/search/Search"
import "./Games.scoped.css"
import Notice from "../../components/notice/Notice";

function Games() {
  const ctx = useContext(UserContext)
  
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('query') ?? ''

  const onNewQuery = (newQuery) => {
      if (newQuery !== query) {
          if (newQuery) {
              setSearchParams({ 
                  query: newQuery
              })
          } else {
              setSearchParams({ })
          }
      }
  }

  const onGet = async () => {
      const games = await getGames(ctx.credentials)
      games.map(game => {
          delete game.title
          game.note = game.store === 'switch' ? 'Switch' : null
          game.id = `${game.store}/${game.storeId}`
          return game
      })
      return games
  }

  const onSearch = async (query) => {
      const games = await searchGames(query, ctx.credentials)
      games.map(game => {
          delete game.title
          game.note = game.store === 'switch' ? 'Switch' : null
          game.id = `${game.store}/${game.storeId}`
          return game
      })
      return games
  }

  return (
    <div className="games">
        <Notice>
          Downloads are powered by torrents. Make sure you have a torrent client installed, like <a target="_blank" href="https://www.fosshub.com/qBittorrent.html?dwl=qbittorrent_4.5.2_x64_setup.exe">qBitTorrent</a>!
        </Notice>
        <Notice>
          Games are added manually by FIIDR's administration team. Please contact a site admin to request a game.
        </Notice>
        <div className="movies-search">
          <Search value={query} callback={onNewQuery}/>
        </div>
        <ProductList query={query} onGet={onGet} baseHref={'games'} onSearch={onSearch}/>
    </div>
  )
}

export default Games
