import BeatLoader from "react-spinners/BeatLoader";

function Loading(props) {
    const finish = props.finish
    const simple = props.simple

    return (
        <div className={`${simple ? 'overlay-simple' : 'overlay'} ${finish ? 'fade-out' : 'fade-in'}`}>
            <BeatLoader
                color='white'
            />
            {props.children}
        </div>
    )
}

export default Loading