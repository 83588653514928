import { useContext } from "react"
import "./ServiceGroup.scoped.css"
import { UserContext } from "../../../../auth/userSession"

function ServiceGroup(props) {
    const classes = 'service-group ' + (props.className ? props.className : '')
    const title = props.title
    const allowRoles = props.allow?.split(' ')
    const ctx = useContext(UserContext)

    if (allowRoles) {
        for (let i = 0; i < allowRoles.length ; ++i) {
            const allow = allowRoles[i]
            if (ctx.roles?.includes(allow) || ctx.roles?.includes('admin')) break
            return null
        }
    }

    return (
        <div className="fade-in">
            <h2>{title}</h2>
            <div className={ classes }>
                {props.children}
            </div>
        </div>
    )
}

export default ServiceGroup;
