import React, { useContext } from 'react'
import { Navigate, Outlet, Routes } from 'react-router-dom'
import { UserContext } from './userSession'

function RequiresRole({role, children }) {
    const ctx = useContext(UserContext)

    if (!ctx.roles?.includes(role) && !ctx.roles?.includes('admin')) {
        return <Navigate to='/'/>
    }

    return (
            children ? 
            <Routes>
                {children}
            </Routes> 
            : 
            <Outlet />
    )
}

export {
    RequiresRole
}