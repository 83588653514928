import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Button from "../../../../components/button/Button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import "./GameDetails.css"
import { UserContext } from "../../../../auth/userSession"
import { getGame } from "../../../../api/games"
import Loading from "../../../../components/loading/Loading"
import Notice from "../../../../components/notice/Notice"

function GameDetails(props) {
    const ctx = useContext(UserContext)
    let { store, id } = useParams();
    const [game, setGame] = useState('');

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
          const data = await getGame(store, id, ctx.credentials)
          setGame(data)
        };
    
        dataFetch();
      }, [ctx.credentials, id]);
    
    const gameStyle = { "--game-background": `url(${game.background})` }

    if (game !== '') {
        // Game loaded
        if (game.errorMessage) {
            return (
                <h1>That game does not exist!</h1>
            )
        }

        return (
            <div style={gameStyle} className="game-details fade-in">
                <div style={gameStyle} className="game-header">
                    <div className="game-info">
                        <h1 className="game-details-metadata-title">{game.title}</h1>
                        { game.downloadUri ? 
                                <Button download steam className="game-details-download" href={game.downloadUri}><FontAwesomeIcon className="game-details-download-icon" icon={faDownload} />Download</Button>
                            :
                                <Button download steam className="game-details-download">Not Available</Button>
                        }
                    </div>
                </div>
                <div className="game-meta">
                    {game.store === 'switch' ?
                        <Notice className="game-notice">This is a Nintendo Switch game. You need to download a Nintendo Switch emulator to play this on PC.</Notice> : null
                    }
                    {game.note ?
                        <Notice className="game-notice">{game.note}</Notice> : null
                    }
                    <div className="game-links">
                        {game.storePage ? 
                                <Button className="game-link" href={game.storePage} newtab>Store Page</Button>
                            :
                                null
                        }
                        {game.website ? 
                            <Button className="game-link" href={game.website} newtab>Website</Button>
                        :
                            null
                        }
                    </div>
                    <img className="game-details-image game-image-desktop" src={game.poster} />
                    <img className="game-details-image game-image-mobile" src={game.header} />
                    <p className="game-details-metadata-description">{game.description}</p>
                </div>
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }

}

export default GameDetails
