import { useEffect, useState } from 'react'
import { UserContext, getInitialContext, getStoredCredentials, logOut } from './auth/userSession'
import { RequiresAuth } from './auth/RequiresAuth'
import { getAuthUser } from './api/users/users'

import Nav from './components/navbar/Nav'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import Login from './components/login/Login'

import Loading from './components/loading/Loading'

import Home from './sub-projects/home/Home'
import Games from './sub-projects/games/Games'
import GameDetails from './sub-projects/games/components/gameDetails/GameDetails'
import Apps from './sub-projects/apps/Apps'
import AppDetails from './sub-projects/apps/components/appDetails/AppDetails'
import Movies from './sub-projects/movies/Movies'
import MovieDetails from './sub-projects/movies/components/details/MovieDetails'
import Shows from './sub-projects/shows/Shows'
import ShowDetails from './sub-projects/shows/components/details/ShowDetails'
import MediaContent from './components/player/MediaContent'
import Frame from './components/frame/Frame'
import { RequiresRole } from './auth/RequiresRole'

function App() {
    const [userCredentials, setUserCredentials] = useState(getStoredCredentials())
    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        if (userCredentials != null) {
            const getUserData = async () => {
                setUserInfo(null)
                const user = await getAuthUser(userCredentials)
                if (!user.success) {
                    logOut()
                    setUserCredentials(null)
                } else {
                    userCredentials.displayName = user.displayName
                    userCredentials.roles = user.roles
                    setUserInfo(user)
                }
            }

            getUserData()
        }
    }, [userCredentials])
    
    if (userCredentials == null) {
        return (
            <UserContext.Provider value={ getInitialContext(userCredentials, setUserCredentials) }>
                <Login /> 
            </UserContext.Provider>
        )
    }

    if (userInfo == null || userCredentials.roles == null) {
        return <Loading />
    }

    return (
        <UserContext.Provider value={ getInitialContext(userCredentials, setUserCredentials) }>
            <Router>
                <Routes>
                    <Route path='/*' element=
                    {
                        <RequiresAuth userCreds={userCredentials}>
                            <Nav userData={userCredentials} userInfo={userInfo}/>
                            <div className="page">
                                <Routes>
                                    <Route path='/' element={ <Home user={userInfo}/> } />
                                    <Route path='/games/*' element={
                                        <RequiresRole role='games'>
                                            <Route path='/' element={ <Games/> }/>
                                            <Route path='/:store/:id' element={ <GameDetails /> } />
                                        </RequiresRole>
                                    }
                                    />
                                    <Route path='/apps/*' element={
                                        <RequiresRole role='apps'>
                                            <Route path='/' element={ <Apps/> }/>
                                            <Route path='/:id' element={ <AppDetails /> } />
                                        </RequiresRole>
                                    }
                                    />
                                    {/*
                                    <Route path='/movies/*' element={
                                        <RequiresRole role='media'>
                                            <Route path='/' element={ <Movies/> } />
                                            <Route path='/:id' element={ <MovieDetails/> } />
                                            <Route path='/:id/watch' element={ <MediaContent movie/> } />
                                        </RequiresRole>
                                    }
                                    />
                                    <Route path='/shows/*' element={
                                        <RequiresRole role='media'>
                                            <Route path='/' element={ <Shows/> } />
                                            <Route path='/:id' element={ <ShowDetails/> } />
                                            <Route path='/:id/seasons/' element={ <ShowDetails/> } />
                                            <Route path='/:id/seasons/:seasonId' element={ <ShowDetails/> } />
                                            <Route path='/:id/episodes/:episodeId' element={ <MediaContent/> } />
                                        </RequiresRole>
                                    }/>
                                    */}
                                    <Route path='/external/sonarr' element={ <Frame src="https://sonarr.fiidr.xyz"/> } />
                                    <Route path='/external/radarr' element={ <Frame src="https://radarr.fiidr.xyz/"/> } />
                                    <Route path='/external/bazarr' element={ <Frame src="https://bazarr.fiidr.xyz/"/> } />
                                    <Route path='/external/prowlarr' element={ <Frame src="https://prowlarr.fiidr.xyz/"/> } />
                                    <Route path='/external/qbit' element={ <Frame src="https://qbit.fiidr.xyz/"/> } />
                                    <Route path='/external/mcmap' element={ <Frame src="https://mc.fiidr.xyz/"/> } />
                                </Routes>
                            </div>
                        </RequiresAuth> 
                    } 
                    />
                </Routes>
            </Router>
        </UserContext.Provider>
    )
}

export {
    App
}