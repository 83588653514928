import { useContext, useRef, useState } from 'react';
import { loginUser, getAuthUser } from '../../api/users/users';
import { UserContext } from '../../auth/userSession';
import './Login.scoped.css';

function Login({ children }) {
    const userNameRef = useRef()
    const passwordRef = useRef()
    const [invalid, setInvalid] = useState(false)
    const ctx = useContext(UserContext)
    const isLoggedIn = ctx?.credentials != null

    const onFormSubmit = (e) => {
        e.preventDefault()
        handleLogin()
    }

    const handleLogin = async () => {
        const username = userNameRef.current?.value
        const password = passwordRef.current?.value

        const isValidUsername = username !== undefined && username.length != 0 && username.match(/\s/) == null
        const isValidPassword = username !== undefined && username.length != 0

        if (isValidUsername && isValidPassword) {
            const res = await loginUser(username, password)
    
            if (res.success) {
                ctx.logIn(username, password, res.id)
            } else {
                setInvalid(true)
            }
        } else {
            setInvalid(true)
        }
    }

    const clearInvalid = () => {
        if (invalid) setInvalid(false)
    }

    return (
        isLoggedIn ? children :
        <div className="login-page fade-in">
            <div className={invalid ? 'invalid-login' : 'invalid-login valid'}>
                {invalid ? 'Invalid username or password' : null}
            </div>
            <div className="login">
                <form onSubmit={onFormSubmit}>
                    <h2 className="username">Username</h2>
                    <input type='text' placeholder='Your username' ref={userNameRef} onInput={clearInvalid}></input>
                    <h2 className="password">Password</h2>
                    <input type='password' placeholder='Your password' ref={passwordRef} onInput={clearInvalid}></input>
                    <button type="submit" className="button" onClick={handleLogin}>Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
